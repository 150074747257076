import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseDebounceExample } from './use-debounce.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "usedebounce"
    }}>{`useDebounce`}</h1>
    <p>{`Use this hook to debounce a value only updating it once within the given delay.
Useful if you need to request data when an input updates but don't want to fetch
the new data on every keystroke.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useDebounce, useInput } from '@fransvilhelm/hooks';

const DebouncedInput = () => {
  const input = useInput('hello world');
  const debouncedValue = useDebounce(input.value, 1000);

  return (
    <div>
      <input type="text" {...input} />
      <p>
        This only updates once every second: <code>{debouncedValue}</code>
      </p>
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseDebounceExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseDebounceExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseDebounceExample mdxType="UseDebounceExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useDebounce`}</inlineCode>{` accepts two parameters, the value to debounce and the delay.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`any`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The value to debounce`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`delay`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of time that must pass before updating the value`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useDebounce`}</inlineCode>{` returns a debounced value of same type as the inputted value, but
it will only update once within the given timeframe`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      